export function layout($) {

  //----------------------------------------------------
  // Custom cursor
  //----------------------------------------------------

  function hoverLinks() {
    $('a').mouseenter(function() {
      $('body').addClass('hover');
    }).mouseleave( function() {
      $('body').removeClass('hover');
    });
  }

  var isTouch = ('ontouchstart' in window);

  if (!isTouch) {
    $('body').addClass('no-touch');

    // set the starting position of the cursor outside of the screen
    let clientX = -50;
    let clientY = -50;
    const innerCursor = document.querySelector('.cursor');

    const initCursor = () => {
      // add listener to track the current mouse position
      document.addEventListener('mousemove', e => {
        clientX = e.clientX;
        clientY = e.clientY;
      });

      // transform the innerCursor to the current mouse position
      // use requestAnimationFrame() for smooth performance
      const render = () => {
        innerCursor.style.transform = `translate(${clientX}px, ${clientY}px)`;
        requestAnimationFrame(render);
      };
      requestAnimationFrame(render);
    };

    initCursor();

   hoverLinks();

  }

  //----------------------------------------------------
  // WINDOW DIMENSIONS & DOCUMENT DIMENSION
  //----------------------------------------------------
  // Window dimensions

  window.windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
  window.windowHeight = window.innerHeight ? window.innerHeight : $(window).height();

  // Document dimension
  window.documentHeight = $(document).height();

  //----------------------------------------------------
  // PRINT WINDOW WIDTH, WINDOW HEIGHT AND PIXEL RATIO
  //----------------------------------------------------

  function printInfo(screenType) {
    let $info0 = $('#info0');
    let $info1 = $('#info1');
    let $info2 = $('#info2');
    let $info3 = $('#info3');

    let pixelRatio = window.devicePixelRatio;


    $info0.text(screenType);
    $info1.text(window.windowWidth + " x " + window.windowHeight);
    $info2.text(pixelRatio);
    $info3.text(window.windowWidth * pixelRatio + " x " + window.windowHeight * pixelRatio);

  }

  //----------------------------------------------------
  // SHOW CORRECT IMAGE
  //----------------------------------------------------

  const minDesktopLarge = 1700;
  const minDesktop = 1270;
  const minIpadH = 1000;
  const minIpadV = 760;
  const minIphone11 = 400;
  //const minIphoneX = 320;


  function realGap() {

    let $contentIpadV = $('.module--mapimages .module_content.landing-ipadv .module_content_images'),
        $contentIpadH = $('.module--mapimages .module_content.landing-ipadh .module_content_images'),
        $contentIphone11promax = $('.module--mapimages .module_content.landing-iphone11promax .module_content_images'),
        $contentIphonex = $('.module--mapimages .module_content.landing-iphonex .module_content_images'),
        //gavVSize = window.windowHeight - 690,
        //gapV = gavVSize+'px',
        //rowsVSize = window.windowHeight - 210,
        rowsVSize = window.windowHeight - 210,
        //rowsV = (rowsVSize >= 480) ? rowsVSize+'px 210px' : '480px 210px',
        rowsV = (rowsVSize >= 480) ? rowsVSize+'px 210px' : '480px 210px',
        //gapHSize = window.windowHeight - 600,
        //gapH = gapHSize+'px',
        rowsHSize = window.windowHeight - 150,
        rowsH = (rowsHSize >= 450) ? rowsHSize+'px 150px' : '450px 150px',
        rowsProSize = window.windowHeight - 470,
        rowsPro = (rowsProSize >= 0) ? '200px 130px '+rowsProSize+'px repeat(2, 70px)' : '200px 130px 0 repeat(2, 70px)',
        rowsXSize = window.windowHeight - 460,
        rowsX = (rowsXSize >= 0) ? '200px 130px '+rowsXSize+'px repeat(2, 65px)' : '200px 130px 0 repeat(2, 65px)';

    $contentIpadV.css({
      //'grid-row-gap' : gapV,
      'grid-template-rows' : rowsV
    });

    $contentIpadH.css({
      'grid-template-rows' : rowsH
    });

    $contentIphone11promax.css({
      'grid-template-rows' : rowsPro
    });

    $contentIphonex.css({
      'grid-template-rows' : rowsX
    });

  }

  function realSize() {
    $('.module--mapimages').css({
      width: window.windowWidth,
      height: window.windowHeight
    });
  }


  function simulatedMapRatio(screenType) {

    if (screenType === 'desktop' || screenType === 'desktop-large' || screenType === 'ipadh' || screenType === 'ipadv' || screenType === 'iphone11promax' || screenType === 'iphonex') {
      $('[data-case].visible').removeAttr('style');
      $('body').addClass('nomapratio');
    } else {
      $('body').removeClass('nomapratio');

      let ratioScreen = window.windowHeight / window.windowWidth;

      $('[data-case].visible').each(function(i, e) {

        let $img = $(e).find('img');
        let imgWidth = $img[0].naturalWidth,
            imgHeight = $img[0].naturalHeight,
            imgRatio = (imgWidth !== 0 && imgHeight !== 0) ? imgHeight / imgWidth : 1;

        let $links = $(e).find('.links');

        let maxWidth = window.windowWidth;

        if (ratioScreen > 1 ) { //Screen Portrait

          if (ratioScreen > imgRatio) {
            let linkHeight = imgHeight * maxWidth / imgWidth;

            $links.css({
              height: linkHeight,
              width: maxWidth
            });

          } else {

            let linkWidth = imgWidth * window.windowHeight / imgHeight;

            $links.css({
              width: linkWidth,
              height: window.windowHeight
            });
          }

        } else { //Screen Landscape
          if (ratioScreen > imgRatio) {
            let linkHeight = imgHeight * maxWidth / imgWidth;

            $links.css({
              height: linkHeight,
              width: maxWidth
            });

          } else {

            let linkWidth = imgWidth * window.windowHeight / imgHeight;

            $links.css({
              width: linkWidth,
              height: window.windowHeight
            });
          }
        }

      });

    }


  }


  function chooseImageMap() {
    let screenType = 'desktop';
    $('body').addClass('desktop');

    if (window.windowWidth >= minDesktopLarge) {
      screenType = 'desktop-large';
      $('body').addClass('desktop');
    } else if (window.windowWidth < minDesktopLarge && window.windowWidth >= minDesktop ) {
      screenType = 'desktop';
      $('body').addClass('desktop');
    } else if (window.windowWidth < minDesktop && window.windowWidth >= minIpadH) {
      screenType = 'ipadh';
      $('body').removeClass('desktop');

    } else if (window.windowWidth < minIpadH && window.windowWidth >= minIpadV) {
      screenType = 'ipadv';
      $('body').removeClass('desktop');

    } else if (window.windowWidth < minIpadV && window.windowWidth >= minIphone11) {
      screenType = 'iphone11promax';
      $('body').removeClass('desktop');

    } else if (window.windowWidth < minIphone11) {
      screenType = 'iphonex';
      $('body').removeClass('desktop');

    }

    $('[data-case="'+screenType+'"]').addClass('visible').siblings().removeClass('visible');

    //imageMapResize();

    simulatedMapRatio(screenType);

    realGap();


    if ($('body').hasClass('dev')) {
      printInfo(screenType);
    }
  }


  realSize();
  chooseImageMap();



  //----------------------------------------------------
  // On Resize and orientationchange
  //----------------------------------------------------

  $(window).on('resize orientationchange', function(){

  	// Window dimensions
  	window.windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
  	window.windowHeight = window.innerHeight ? window.innerHeight : $(window).height();

  	// Document dimension
  	window.documentHeight = $(document).height();

    realSize();
    chooseImageMap();

  });




}
