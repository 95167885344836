//----------------------------------//
//                                  //
//  Browser identify                //
//                                  //
//----------------------------------//

export function browser($) {
  window.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  window.isFirefox = typeof InstallTrigger !== 'undefined';
  window.isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
  window.isIE = /*@cc_on!@*/false || !!document.documentMode;
  window.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  window.isEdge = !window.isIE && !!window.StyleMedia;
  window.isChrome = (!!window.chrome && !!window.chrome.webstore) || navigator.userAgent.indexOf('Chrome') >= 0;
  window.isBlink = (window.isChrome || window.isOpera) && !!window.CSS;
  window.isiPhone = navigator.userAgent.indexOf('iPhone') >= 0;
  window.isiPad = navigator.userAgent.indexOf('iPad') >= 0;
  window.isiOS = window.isiPhone || window.isiPad;
  window.isAndroid = navigator.userAgent.indexOf('Android') >= 0;
  if (window.isOpera) {
    $('html').addClass('isOpera');
  }
  if (window.isFirefox) {
    $('html').addClass('isFirefox');
  }
  if (window.isSafari) {
    $('html').addClass('isSafari');
  }
  if (window.isIE) {
    $('html').addClass('isIE');
  }
  if (window.isIE11) {
    $('html').addClass('isIE11');
  }
  if (window.isEdge) {
    $('html').addClass('isEdge');
  }
  if (window.isChrome) {
    $('html').addClass('isChrome');
  }
  if (window.isBlink) {
    $('html').addClass('isBlink');
  }
  if (window.isiPhone) {
    $('html').addClass('isiPhone');
  }
  if (window.isiPad) {
    $('html').addClass('isiPad');
  }
  if (window.isiOS) {
    $('html').addClass('isiOS');
  }
  if (window.isAndroid) {
    $('html').addClass('isAndroid');
  }


  /*======================================================================*/
  /* Avoid Safari to use cached JS on browser back navigation             */
  /*======================================================================*/

  if ($('html').hasClass('isSafari')) {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }
}
